
const fetchVetSubStatus = async (apiUrl, token, setVetSubStatus) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/check_access`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (response.ok) {
      const userStatus = await response.json();
      // console.log(userStatus.user_status);
      setVetSubStatus(userStatus?.user_status);
    }
  } catch (error) {
    console.error("Error fetching vet subscription status:", error);
    setVetSubStatus("Error");
  }
};

const getPets = async (apiUrl, setPets, navigate, petIds, token) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/pets`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (response.ok) {
      const allPets = await response.json();
      const filteredPets = allPets.filter((pet) => petIds.includes(pet.id));
      setPets(filteredPets);
    } else if (response.status === 401) {
      localStorage.removeItem("token");
      navigate("/login");
    } else {
      throw new Error("Failed to fetch pets");
    }
  } catch (error) {
    console.error("Error fetching pets:", error);
  }
};

const getVeterinarianId = async (
  apiUrl,
  setNewAppointment,
  navigate,
  token
) => {
  try {
    const response = await fetch(`${apiUrl}/api/v1/check_session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (response.ok) {
      const data = await response.json();
      setNewAppointment((prev) => ({
        ...prev,
        veterinarian_id: data.vet_id,
      }));
    } else if (response.status === 401) {
      localStorage.removeItem("token");
      navigate("/login");
    } else {
      throw new Error("Failed to fetch veterinarian ID");
    }
  } catch (error) {
    console.error("Error fetching veterinarian ID:", error);
  }
};

const newPetSubmit = async (
  token,
  setPets,
  setNewAppointment,
  setIsAddingNewPet,
  setNewPet,
  newPet,
  apiUrl,
  pets,
  newAppointment
) => {
  if (!newPet.name.trim()) {
    alert("Pet name is required");
    return;
  }

  const petData = {
    ...newPet,
    age: newPet.age ? parseInt(newPet.age, 10) : null,
    weight: newPet.weight ? parseInt(newPet.weight, 10) : null,
  };

  try {
    const response = await fetch(`${apiUrl}/api/v1/create-pet`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(petData),
      // credentials: 'include'
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Failed to create new pet");
    }

    const createdPet = await response.json();
    setPets([...pets, createdPet]);
    setNewAppointment({ ...newAppointment, pet_id: createdPet.id });
    setIsAddingNewPet(false);
    setNewPet({ name: "", age: "", breed: "", sex: "", weight: "" });
  } catch (error) {
    console.error("Error creating new pet:", error);
    alert(`Failed to create new pet. Error: ${error.message}`);
  }
};

const handleAppointmentSubmit = async (
  e,
  token,
  apiUrl,
  handleClose,
  onAppointmentCreated,
  setNewAppointment,
  isAddingNewPet,
  newAppointment,
  navigate
) => {
  e.preventDefault();
  if (isAddingNewPet) {
    alert("Please add the new pet before submitting the appointment");
    return;
  }
  const { pet_id, date, time, veterinarian_id, ...rest } = newAppointment;

  if (!pet_id || !date || !time) {
    alert("Please fill in all required fields: Pet, Date, and Time");
    return;
  }

  const localDateTime = new Date(`${date}T${time}`);
  const utcDateTime = localDateTime.toISOString().replace("Z", "+00:00");

  const appointmentData = {
    pet_id: parseInt(pet_id),
    veterinarian_id: parseInt(veterinarian_id),
    datetime_utc: utcDateTime,
    ...rest,
  };

  try {
    const response = await fetch(`${apiUrl}/api/v1/create_appointment`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(appointmentData),
      credentials: "include",
    });

    if (response.ok) {
      const data = await response.json();
      // console.log("Appointment created:", data);
      handleClose();
      onAppointmentCreated();
      setNewAppointment({
        pet_id: "",
        date: "",
        time: "",
        reason: "",
        meeting_link: "In Office Visit",
        veterinarian_id: "",
      });
    } else if (response.status === 401) {
      localStorage.removeItem("token");
      navigate("/login");
    } else {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    console.error("Error:", error);
    alert("Failed to create appointment. Please try again.");
  }
};

export {
  fetchVetSubStatus,
  getPets,
  getVeterinarianId,
  newPetSubmit,
  handleAppointmentSubmit,
};
