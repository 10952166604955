import { Typography, Button, Box } from "@mui/material";
import StandardButton from "../StyledComponents/StandardButton";
import { useNavigate } from "react-router-dom";

export default function PremiumUpgrade() {
  const navigate = useNavigate();
    return (
        <Box
          sx={{
            borderRadius: "10px",
            mx: 2,
            padding: 7,
            maxHeight: "100%",
            maxWidth: "100%",
            boxSizing: "border-box",
            overflow: "auto",
            textAlign: "center",
          }}
          mx={4}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              color: "black",
              display: "inline-block",
            }}
          >
            <strong>Access Denied: Appointment Limit Reached </strong> <br />
          </Typography>
          <Typography variant="body2" sx={{ marginTop: 0.2 }}>
            Upgrade to Premium Subscription for Unlimited Appointments
          </Typography>
          <StandardButton text="Upgrade to Premium" handleClick={() => navigate("/account-settings")} />
        </Box>
    )
}
