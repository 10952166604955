const requestOptions = (url) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${url}`,
    },
  };
  return options
}

export const scheduleCalendlyEventApi = async (event_uri, calendlyApiUrl) => {
  const options = requestOptions(calendlyApiUrl)
  const response = await fetch(event_uri, options);
  return response;
};

export const getCalendlyInviteeData = async (invitee_uri, calendlyApiUrl) => {
  const options = requestOptions(calendlyApiUrl)
  const response = await fetch(invitee_uri, options);
  return response;
}
