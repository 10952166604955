// API calls related to admin actions
import { jwtDecode } from "jwt-decode";

const apiUrl = process.env.REACT_APP_API_URL || "";

// Get user information from JWT token
export const getUserFromToken = () => {
  const token = localStorage.getItem("token");
  if (!token) return null;

  try {
    return jwtDecode(token);
  } catch (error) {
    console.error("Invalid token:", error);
    return null;
  }
};