import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import StandardButton from "./StyledComponents/StandardButton";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useTokenRedirect from "./../utils/authUtils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(diagnosis, selectedDiagnoses, theme) {
  return {
    fontWeight:
      selectedDiagnoses.indexOf(diagnosis) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SelectDiagnosis({
  differentialList,
  aptId,
  onDifferentialSubmit,
}) {
  const theme = useTheme();
  const [selectedDiagnoses, setSelectedDiagnoses] = useState([]);
  const [open, setOpen] = useState(false);
  const [differentialsSubmitted, setDifferentialsSubmitted] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || '';
  const { getTokenOrRedirect } = useTokenRedirect();
  const token = getTokenOrRedirect()

  const fetchSelectedDifferentials = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/api/v1/get-selected-differentials/${aptId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      if (response.ok) {
        const data = await response.json();
        setSelectedDiagnoses(data.selectedDiagnoses || []);
      } else if (response.status === 401) {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error("Error fetching selected differentials:", error);
    }
  }, [aptId, navigate, apiUrl]);

  useEffect(() => {
    fetchSelectedDifferentials();
  }, [fetchSelectedDifferentials]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDiagnoses(typeof value === "string" ? value.split(",") : value);
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/v1/update-differentials/${aptId}`, {
        method: "PATCH",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          selectedDiagnoses,
        }),
        credentials: 'include'
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setDifferentialsSubmitted(true);
        setOpen(true);
        handleGenerateTreatmentPlan();
      } else if (response.status === 401) {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error("Error updating differentials:", error);
    }
  };

  const handleRemove = async (diagnosis) => {
    try {
      const response = await fetch(`${apiUrl}/api/v1/remove-differential/${aptId}`, {
        method: "PATCH",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          diagnosis,
        }),
        credentials: 'include'
      });

      if (response.ok) {
        const data = await response.json();
        setSelectedDiagnoses((prev) => prev.filter((d) => d !== diagnosis));
      } else if (response.status === 401) {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error("Error removing differential:", error);
    }
  };

  const handleGenerateTreatmentPlan = () => onDifferentialSubmit();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <FormControl sx={{ mt: 3, mb: 2, width: "100%" }}>
        <InputLabel id="select-diagnosis-label">Select Diagnoses</InputLabel>
        <Select
          labelId="select-diagnosis-label"
          id="select-diagnosis"
          multiple
          value={selectedDiagnoses}
          onChange={handleChange}
          input={
            <OutlinedInput id="select-multiple-chip" label="Select Diagnosis" />
          }
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemove(value);
                  }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {differentialList.map((differential) => (
            <MenuItem
              key={differential.diagnosis}
              value={differential.diagnosis}
              style={getStyles(
                differential.diagnosis,
                selectedDiagnoses,
                theme
              )}
            >
              {differential.diagnosis}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box display="flex" mt={1}>
        <StandardButton text="Submit Diagnoses & Generate Treatment Plan" handleClick={handleSubmit} />
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Differentials Submitted Successfully
          </Alert>
        </Snackbar>
      </Box>
    </div>
  );
}
