import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

function VeterinarianFilter({
  selectedVet,
  setSelectedVet,
  vetOptions,
  isMobile,
}) {
  return (
    <FormControl fullWidth>
      {/* <InputLabel id="vet-select-label" shrink>
        Veterinarians
      </InputLabel> */}
      <Select
        labelId="vet-select-label"
        value={selectedVet}
        onChange={(event) => setSelectedVet(event.target.value)}
        displayEmpty
        sx={{
          color: "black",
          fontSize: isMobile ? "0.75rem" : "1rem",
        }}
      >
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        {vetOptions.map((vet, index) => (
          <MenuItem
            key={index}
            value={vet}
            sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}
          >
            {vet}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default VeterinarianFilter;
