const fetchAppointments = async (apiUrl, token) => {
    return fetch(`${apiUrl}/appointments`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      credentials: "include",
    }).then((r) => r.json());
  };
  
const fetchPetNames = async (apiUrl, token, inputValue, practiceAdminId) => {
    return fetch(
      `${apiUrl}/api/v1/pet-names?practice_admin_id=${practiceAdminId}&q=${inputValue}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    ).then((response) => response.json());
  };

export { fetchAppointments, fetchPetNames }