import { useNavigate } from 'react-router-dom';

const useTokenRedirect = () => {
  const navigate = useNavigate();

  const getTokenOrRedirect = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return null;
    }
    return token;
  };
  return { getTokenOrRedirect };
};

export default useTokenRedirect;