import { Outlet, useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import VetNavBar from "../components/VetNavBar";
import OwnerNavBar from "../components/OwnerNavBar";
import AdminNavBar from "../components/AdminNavBar";
import { checkSessionApi } from "../api/checkSessionApi";
import { useEffect, useState, useCallback } from "react";

function App() {
  const [userType, setUserType] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";

  const checkSession = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const response = await checkSessionApi(apiUrl, token);
      if (response.ok) {
        const data = await response.json();

        if (data && data.user_type) {
          setUserType(data.user_type);
        } else {
          console.warn("User type is missing in the response data.");
        }
      }
    } else {
      setUserType("");
    }
  }, [apiUrl]);

  useEffect(() => {
    checkSession();
  }, [checkSession]);

  const handleLogout = async () => {
    localStorage.removeItem("token");
    setUserType("");
    navigate("/");
  };

  const handleLogin = () => {
    checkSession();
  };

  return (
    <div className="app">
      {userType === "vet" ? (
        <VetNavBar />
      ) : userType === "owner" ? (
        <OwnerNavBar />
      ) : userType === "admin" ? (
        <AdminNavBar />
      ) : (
        <NavBar />
      )}
      <Outlet context={{ handleLogin, handleLogout }} />
    </div>
  );
}

export default App;
