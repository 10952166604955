import StandardButton from "./StyledComponents/StandardButton";
import { useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import VetAppointmentRowMobile from "./VetAppointmentRowMobile";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import NewAppointment from "./NewAppointment/NewAppointment";
import { ThemeProvider } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useNavigate } from "react-router-dom";
import * as appointmentApi from '../api/appointmentApi';
import { getUserFromToken } from '../api/adminApi';

export default function VetAppointmentTableMobile({ theme }) {
  const [apts, setApts] = useState([]);
  const [filteredApts, setFilteredApts] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [petOptions, setPetOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedPetName, setSelectedPetName] = useState("");
  const [vetOptions, setVetOptions] = useState([]);
  const [selectedVet, setSelectedVet] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [userData, setUserData] = useState(null);

  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [rowsPerPage, setRowsPerPage] = useState(isMobile ? 10 : 10);

  const apiUrl = process.env.REACT_APP_API_URL || "";

  // Set user data on mount
  useEffect(() => {
    const user = getUserFromToken();
    if (!user) {
      navigate("/login");
      return;
    }
    setUserData(user);
  }, [navigate]);

  // Only fetch appointments after we have user data
  useEffect(() => {
    if (!userData?.sub) return;
    
    fetchAppointments();
  }, [userData]);

  // Fetch appointments and set apts, filteredApts, vetOptions
  const fetchAppointments = () => {
    appointmentApi
      .fetchAppointments()
      .then((aptArray) => {
        setApts(aptArray);
        setFilteredApts(aptArray);
        const vets = [...new Set(
          aptArray.map(apt => `${apt.veterinarian.first_name} ${apt.veterinarian.last_name}`)
        )];
        setVetOptions(vets);
      })
      .catch((error) => {
        console.error('Failed to fetch appointments:', error);
        if (error.message === "Unauthorized") {
          localStorage.removeItem("token");
          navigate("/login");
        }
      });
  };


  useEffect(() => {
    let filtered = apts;
    if (selectedPetName) {
      filtered = filtered.filter((apt) => apt.pet.name === selectedPetName);
    }
    if (selectedVet) {
      filtered = filtered.filter(
        (apt) =>
          `${apt.veterinarian.first_name} ${apt.veterinarian.last_name}` ===
          selectedVet
      );
    }
    if (selectedDate) {
      filtered = filtered.filter((apt) => {
        const aptDate = new Date(apt.datetime_utc);
        return aptDate.toDateString() === selectedDate.toDateString();
      });
    }
    setFilteredApts(filtered);
  }, [selectedPetName, selectedVet, selectedDate]);

  // Handle Page Change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedApts = filteredApts.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const petIds = [...new Set(apts.map((apt) => apt.pet_id))];

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    fetchAppointments(); // Fetch appointments after closing the form
  };

  // Fetch pet name options based on user input
  useEffect(() => {
    if (!inputValue || inputValue.length <= 1 || !userData?.sub) return;
    
    appointmentApi.fetchPetOptions(inputValue)
      .then(data => {
        setPetOptions(data);
      })
      .catch(error => {
        console.error('Failed to fetch pet options:', error);
        setPetOptions([]);
        if (error.message === "Unauthorized") {
          localStorage.removeItem("token");
          navigate("/login");
        }
      });
  }, [inputValue, userData, navigate]);

  // Handle filter change
  const handleFilterChange = (event, value) => {
    console.log('Pet filter changed to:', value);
    setSelectedPetName(value);
  };

  const handleVetChange = (event) => {
    setSelectedVet(event.target.value);
  };

  const handleDateChange = (date) => {
    console.log('Date filter changed to:', date);
    setSelectedDate(date);
  };

  const resetFilters = () => {
    setSelectedPetName("");
    setSelectedVet("");
    setSelectedDate(null);
  };

  // Early return if no user data
  if (!userData?.sub) {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ backgroundColor: "#BDE9FB", padding: isMobile ? 1 : 2 }}>
          <Typography>Loading...</Typography>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ backgroundColor: "#BDE9FB", padding: isMobile ? 1 : 2 }}>
          <Typography
            variant="body1"
            sx={{
              color: "black",
              mb: isMobile ? 0.5 : 1,
              display: "inline-block",
              fontSize: isMobile ? "0.9rem" : "1rem",
            }}
          >
            Welcome, {userData.sub.username}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: isMobile ? "flex-start" : "center",
              mb: isMobile ? 0.5 : 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Typography
                variant={isMobile ? "h5" : "h4"}
                sx={{
                  color: "black",
                  mb: isMobile ? 0 : 1,
                  textTransform: "capitalize",
                }}
              >
                Appointments
              </Typography>
              <IconButton
                onClick={resetFilters}
                sx={{
                  ml: isMobile ? 1 : 1.5,
                  mb: 1,
                  backgroundColor: "white",
                }}
                size="small"
              >
                <RestartAltIcon />
              </IconButton>
            </Box>

            <StandardButton
              text="+ Add Appointment"
              handleClick={handleOpen}
              isMobile={isMobile}
            />
          </Box>
          <NewAppointment
            open={open}
            theme={theme}
            handleClose={handleClose}
            petIds={petIds}
            onAppointmentCreated={fetchAppointments}
          />

          {apts.length > 0 ? (
            <Paper
              sx={{
                width: "100%",
                overflow: "hidden",
                borderRadius: "15px",
                marginTop: isMobile ? 3 : 0,
              }}
            >
              <TableContainer
                sx={{
                  maxHeight: isMobile ? 1000 : 700,
                  overflowX: "auto",
                  overflowY: isMobile ? "auto" : "auto",
                }}
              >
                <Table stickyHeader aria-label="appointments table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          minWidth: isMobile ? 75 : 150,
                          width: isMobile ? 75 : 200,
                          whiteSpace: isMobile ? "normal" : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Autocomplete
                          sx={{
                            "& .MuiInputBase-input": {
                              fontSize: isMobile ? "0.75rem" : "1rem",
                            },
                          }}
                          options={petOptions}
                          getOptionLabel={(option) => option}
                          onInputChange={(e, value) => setInputValue(value)}
                          onChange={handleFilterChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={isMobile ? "Pet" : "Pet Name"}
                              variant="outlined"
                              fullWidth
                              InputLabelProps={{
                                sx: { fontSize: isMobile ? "0.75rem" : "1rem" },
                              }}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          width: isMobile ? 75 : 200,
                          minWidth: isMobile ? 75 : 150,
                          whiteSpace: isMobile ? "none" : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <DatePicker
                          label={isMobile ? "" : "Filter by Date"}
                          value={selectedDate}
                          onChange={handleDateChange}
                          sx={{
                            "& .MuiInputBase-input": {
                              fontSize: isMobile ? "0.75rem" : "1rem",
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              InputLabelProps={{
                                sx: { fontSize: isMobile ? "0.75rem" : "1rem" },
                              }}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          minWidth: isMobile ? 75 : 150,
                          width: isMobile ? 75 : 150,
                          whiteSpace: isMobile ? "none" : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Time
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          minWidth: isMobile ? 75 : 150,
                          width: isMobile ? 75 : 150,
                          whiteSpace: isMobile ? "none" : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Appointment Type
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          minWidth: isMobile ? 75 : 150,
                          whiteSpace: isMobile ? "none" : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Reason for Visit
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          minWidth: isMobile ? 75 : 150,
                          whiteSpace: isMobile ? "none" : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="vet-select-label" shrink>
                            Veterinarians
                          </InputLabel>
                          <Select
                            onChange={handleVetChange}
                            labelId="vet-select-label"
                            value={selectedVet}
                            label="Veterinarians"
                            displayEmpty
                            sx={{
                              color: "black",
                              fontSize: isMobile ? "0.75rem" : "1rem",
                            }}
                          >
                            <MenuItem value="">
                              <em>All</em>
                            </MenuItem>
                            {vetOptions.map((vet, i) => (
                              <MenuItem
                                sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}
                                key={i}
                                value={vet}
                              >
                                {vet}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          width: isMobile ? 75 : 150,
                          whiteSpace: isMobile ? "none" : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Status
                      </TableCell>

                      <TableCell
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          minWidth: isMobile ? 75 : 150,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Summary
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          minWidth: isMobile ? 75 : 150,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Differentials
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          minWidth: isMobile ? 40 : 85,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Join
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedApts.map((apt) => (
                      <VetAppointmentRowMobile theme={theme} key={apt.id} apt={apt} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={filteredApts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={isMobile ? "" : "Rows per page"}
                rowsPerPageOptions={isMobile ? [] : [5, 10, 25, 50]}
                sx={{
                  fontSize: isMobile ? "small" : "medium",
                  ".MuiTablePagination-selectLabel, .MuiTablePagination-input":
                    {
                      fontSize: isMobile ? "small" : "medium",
                    },
                }}
              />
            </Paper>
          ) : (
            <p>No appointments to display</p>
          )}
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

// export default VetAppointmentTableMobile;