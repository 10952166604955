import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import useTokenRedirect from "../utils/authUtils";
import OverFlowMenuButton from "./StyledComponents/OverFlowMenuButton";
import {
  Typography,
  Select,
  MenuItem,
  Box,
  useMediaQuery,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import StandardButton from "./StyledComponents/StandardButton";
import { updateAppointmentApi } from "../api/updateAppointmentApi";
import { deleteAppointmentApi } from "../api/appointmentApi";

function VetAppointmentRow({
  apt,
  apts,
  theme,
  isEditing,
  setIsEditing,
  editableData,
  setEditableData,
  setApts,
  handleChange,
}) {
  const [status, setStatus] = useState(apt.status);
  const [open, setOpen] = useState(false);
  const [summaryExists, setSummaryExists] = useState(false);
  const [differentialsExist, setDifferentialsExist] = useState(false);
  const datetime_utc = new Date(apt.datetime_utc + "Z");
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getTokenOrRedirect } = useTokenRedirect();
  const token = getTokenOrRedirect();

  console.log("VetAptRow editable data:", editableData);

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: userTimeZone,
  };

  const localDateTime = datetime_utc.toLocaleString("en-US", options);
  const [date, time] = localDateTime.split(", ");

  const checkMedicalSummary = useCallback(async () => {
    try {
      const response = await fetch(
        `${apiUrl}/api/v1/check-medical-summary/${apt.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSummaryExists(data.exists);
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (err) {
      console.error(err);
    }
  }, [apt.id, navigate, apiUrl]);

  const checkDifferentialList = useCallback(async () => {
    try {
      const response = await fetch(
        `${apiUrl}/api/v1/check-differential-list/${apt.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDifferentialsExist(data.exists);
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (err) {
      console.error(err);
    }
  }, [apt.id, navigate, apiUrl]);

  const updateAppointmentStatus = useCallback(
    async (newStatus) => {
      try {
        const response = await fetch(`${apiUrl}/api/v1/appointment/${apt.id}`, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: newStatus }),
        });

        if (response.ok) {
          setStatus(newStatus);
        } else if (response.status === 401) {
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          throw new Error("Network response was not ok");
        }
      } catch (err) {
        console.error(err);
      }
    },
    [apt.id, navigate, apiUrl]
  );

  useEffect(() => {
    checkMedicalSummary();
    checkDifferentialList();
  }, [checkMedicalSummary, checkDifferentialList]);

  const handleSave = async () => {
    try {
      const updatedApt = await updateAppointmentApi(
        apiUrl,
        apt.id,
        token,
        editableData
      );
      setApts((prevApts) =>
        prevApts.map((apt) =>
          apt.id === updatedApt.id ? { ...apt, ...updatedApt } : apt
        )
      );
      setIsEditing(false);
      setOpen(true);
    } catch (error) {
      console.error("Error updating appointment details:", error);
    }
  };

  const handleDeleteAppointment = async () => {
    const deletedAptId = apt.id;
    try {
      const response = await deleteAppointmentApi(apiUrl, apt.id, token);
      if (response.ok) {
        const updatedApts = apts.filter((apt) => {
          return apt.id !== deletedAptId;
        });
        setApts(updatedApts);
      } else {
        console.error("Failed to delete appointment");
      }
    } catch (error) {
      console.error("Error deleting the appointment", error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box sx={{ width: "30%" }}>
        <Typography sx={{ mb: 4 }}>
          Scheduled with Calendly?
          <Box
            component="span"
            sx={{
              ml: 1,
              color: "white",
              backgroundColor:
                apt.calendly_event_uri === "None" ? "red" : "green",
              borderRadius: "4px",
              paddingX: 1,
              paddingY: 0.5,
              display: "inline-block",
            }}
          >
            {apt.calendly_event_uri === "None" ? "No" : "Yes"}
          </Box>
        </Typography>
        <Typography sx={{ mb: 2 }}>
          <strong>Manage Patient Records:</strong>
        </Typography>
        <Link to={`/appointment/${apt.id}`} style={{ textDecoration: "none" }}>
          <Typography
            fontSize={isMobile ? "0.75rem" : "1rem"}
            variant="body2"
            sx={{
              mb: 1,
              color: summaryExists ? "#3991da" : "#0fbeb7",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {summaryExists ? (
              <strong>Update Summary</strong>
            ) : (
              <strong>Create Summary</strong>
            )}
          </Typography>
        </Link>
        <Link
          to={`/differentials/${apt.id}`}
          style={{ textDecoration: "none" }}
        >
          <Typography
            variant="body2"
            fontSize={isMobile ? "0.75rem" : "1rem"}
            sx={{
              color: differentialsExist ? "#3991da" : "#0fbeb7",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {differentialsExist ? (
              <strong>Update Differentials</strong>
            ) : (
              <strong>Create Differentials</strong>
            )}
          </Typography>
        </Link>
        <Typography sx={{ mb: 2, mt: 5 }}>
          <strong>Appointment Status:</strong>
        </Typography>
        <Select
          sx={{
            fontSize: isMobile ? "0.75rem" : "1rem",
            width: isMobile ? 75 : "none",
            mb: 10,
          }}
          value={status}
          onChange={(e) => updateAppointmentStatus(e.target.value)}
        >
          <MenuItem
            sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}
            value="Scheduled"
          >
            Scheduled
          </MenuItem>
          <MenuItem
            sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}
            value="Dictation Complete"
          >
            Dictation Complete
          </MenuItem>
          <MenuItem
            sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}
            value="Medical Chart Complete"
          >
            Medical Chart Complete
          </MenuItem>
          <MenuItem
            sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}
            value="Recorded in PIMS"
          >
            Recorded in PIMS
          </MenuItem>
        </Select>
      </Box>
      <Box sx={{ width: "30%", mb: 5 }}>
        {!isEditing ? (
          <Typography sx={{ fontSize: "17px" }}>
            <strong>Owner Name</strong>
            <br />
            {apt?.pet?.owner?.first_name
              ? apt?.pet?.owner?.first_name + " " + apt?.pet?.owner?.last_name
              : "No Owner Assigned"}
            <br />
            <br />
            <strong>Owner Email</strong>
            <br />
            {apt?.pet?.owner?.email
              ? apt?.pet?.owner?.email
              : "No Owner Assigned"}
            <br />
            <br />
            <strong>Pet Breed</strong>
            <br />
            {apt?.pet?.breed}
            <br />
            <br />
            <strong>Pet Age</strong>
            <br />
            {apt?.pet?.age}
            <br />
            <br />
            <strong>Pet Sex</strong>
            <br />
            {apt?.pet?.sex}
          </Typography>
        ) : (
          <Box>
            <TextField
              label="Owner First Name"
              fullWidth
              margin="normal"
              value={editableData.owner_first_name}
              onChange={(e) => handleChange("owner_first_name", e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Owner Last Name"
              fullWidth
              margin="normal"
              value={editableData.owner_last_name}
              onChange={(e) => handleChange("owner_last_name", e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Owner Email"
              fullWidth
              margin="normal"
              value={editableData.owner_email}
              onChange={(e) => handleChange("owner_email", e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Pet Breed"
              fullWidth
              margin="normal"
              value={editableData.breed}
              onChange={(e) => handleChange("breed", e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Pet Age"
              fullWidth
              margin="normal"
              value={editableData.age}
              onChange={(e) => handleChange("age", e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Pet Sex"
              fullWidth
              margin="normal"
              value={editableData.sex}
              onChange={(e) => handleChange("sex", e.target.value)}
              sx={{ mb: 2 }}
            />
          </Box>
        )}
      </Box>
      {apt.calendly_event_uri === "None" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "20%",
            mb: 43,
          }}
        >
          <OverFlowMenuButton
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            handleDeleteAppointment={handleDeleteAppointment}
          />
          {isEditing && (
            <StandardButton text="Save Changes" handleClick={handleSave} />
          )}
        </Box>
      )}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Appointment Details Saved Successfully
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default VetAppointmentRow;
