import { Autocomplete, TextField } from "@mui/material";

function PetNameFilter({
  selectedPetName,
  setSelectedPetName,
  petOptions,
  inputValue,
  setInputValue,
  isMobile,
}) {
  return (
    <Autocomplete
      options={petOptions}
      getOptionLabel={(option) => option}
      onInputChange={(e, value) => setInputValue(value)}
      onChange={(event, value) => setSelectedPetName(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={isMobile ? "Pet" : "Pet Name"}
          variant="outlined"
          fullWidth
          InputLabelProps={{
            sx: { fontSize: isMobile ? "0.75rem" : "1rem" },
          }}
        />
      )}
    />
  );
}

export default PetNameFilter;
