// API calls related to appointments

const apiUrl = process.env.REACT_APP_API_URL || "";

export const fetchAppointments = async () => {
    const response = await fetch(`${apiUrl}/appointments`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
  
    if (!response.ok) {
      if (response.status === 401) {
        throw new Error("Unauthorized");
      }
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const fetchPetOptions = async (searchValue) => {
  if (!searchValue || searchValue.length <= 1) {
    return [];
  }

  const response = await fetch(
    `${apiUrl}/api/v1/pet-names?q=${searchValue}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch pet options");
  }

  return response.json();
};

export const creatAppointmentApi = async (apiUrl, appointmentData, token) => {
  const response = await fetch(`${apiUrl}/api/v1/create_appointment`, {
    method: 'POST',
    body: JSON.stringify(appointmentData),
    headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    },
    credentials: 'include'
});
return response
}

export const deleteAppointmentApi = async (apiUrl, aptId, token) => {
  const response = await fetch(
    `${apiUrl}/api/v1/delete-appointment/${aptId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return response
}