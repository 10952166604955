import {
  TextField,
  Typography,
  MenuItem,
  InputAdornment,
  Select,
  FormControl,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StandardButton from "../StyledComponents/StandardButton";

export default function NewPetForm({
  newPet,
  handleNewPetChange,
  handleAgeTypeChange,
  handleNewPetSubmit,
  ageType,
  closeAddNewPetDialogue
}) {
  return (
    <Box
      sx={{
        mt: 2,
        mb: 2,
        p: 2,
        border: "1px solid #ccc",
        borderRadius: "4px",
        position: "relative", // Add position relative for absolute positioning
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Add New Pet
        </Typography>
        <IconButton
          onClick={() => closeAddNewPetDialogue()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <TextField
        label="Pet Name"
        name="name"
        value={newPet.name}
        onChange={handleNewPetChange}
        fullWidth
        margin="normal"
        required
      />

      <TextField
        label="Age"
        name="age"
        value={newPet.age}
        onChange={handleNewPetChange}
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Select
                value={ageType}
                sx={{ paddingRight: "15px" }}
                onChange={handleAgeTypeChange}
                displayEmpty
                inputProps={{ "aria-label": "Age type" }}
              >
                <MenuItem value="months">Months</MenuItem>
                <MenuItem value="years">Years</MenuItem>
              </Select>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        label="Breed"
        name="breed"
        value={newPet.breed}
        onChange={handleNewPetChange}
        fullWidth
        margin="normal"
      />

      <FormControl fullWidth margin="normal">
        <Select
          value={newPet.sex}
          onChange={handleNewPetChange}
          name="sex"
          displayEmpty
          renderValue={(value) => (value ? value : "Sex")}
        >
          <MenuItem value="" disabled>
            select pet sex
          </MenuItem>
          <MenuItem value="male-neutered">Male Neutered</MenuItem>
          <MenuItem value="male-intact">Male Intact</MenuItem>
          <MenuItem value="female-spayed">Female Spayed</MenuItem>
          <MenuItem value="female-intact">Female Intact</MenuItem>
        </Select>
      </FormControl>

      <TextField
        label="Weight"
        name="weight"
        value={newPet.weight}
        onChange={handleNewPetChange}
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
        }}
      />
      <StandardButton
        text="Add Pet"
        handleClick={handleNewPetSubmit}
      />
    </Box>
  );
}
