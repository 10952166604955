export const getSummaryWithDiagnosis = async (apiUrl, aptId, token) => {
  const response = await fetch(
    `${apiUrl}/api/v1/get-summary-with-diagnosis/${aptId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};
