import React from 'react';
import Button from '@mui/material/Button';

const StandardButton = ({ text, handleClick, size="medium" }) => {
  return (
    <Button
      variant="contained"
      onClick={handleClick}
      size={size}
      sx={{
        mb: 1,
        mt: 1,
        borderRadius: "25px",
        backgroundColor: "#0FBEB7",
        textTransform: "capitalize",
        "&:hover": {
          backgroundColor: "#FFFFFF",
          color: "#757575",
        },
      }}
    >
      {text}
    </Button>
  );
};

export default StandardButton;