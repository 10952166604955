import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
  useTheme,
  Typography,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

function VetNavBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  console.log(isMobile)

  const [mobileOpen, setMobileOpen] = useState(false); 

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List>
        <ListItem component={NavLink} to="/" button>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem component={NavLink} to="/about" button>
          <ListItemText primary="About" />
        </ListItem>
        <ListItem component={NavLink} to="/appointments" button>
          <ListItemText primary="Appointments" />
        </ListItem>
        <ListItem component={NavLink} to="/account-settings" button>
          <ListItemText primary="Account" />
        </ListItem>
        <ListItem component={NavLink} to="/feedback-page" button>
          <ListItemText primary="Feedback" />
        </ListItem>
        <ListItem component={NavLink} to="/logout" button>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: 'white', borderBottom: '1.5px solid black' }}>
      <Toolbar>
        <Typography
          variant="h6"
          component={NavLink}
          to="/"
          sx={{
            flexGrow: 1,
            color: '#333',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
        >
          Home
        </Typography>
        {isMobile ? (
          <>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon sx={{ color: '#333' }} />
            </IconButton>
            <Drawer
              anchor="right"
              open={mobileOpen} 
              onClose={handleDrawerToggle}
              ModalProps={{ keepMounted: true }}
            >
              {drawer}
            </Drawer>
          </>
        ) : (
          <Box sx={{ display: 'flex', gap: '20px' }}>
            <Typography component={NavLink} to="/about" sx={navLinkStyles}>
              About
            </Typography>
            <Typography component={NavLink} to="/appointments" sx={navLinkStyles}>
              Appointments
            </Typography>
            <Typography component={NavLink} to="/account-settings" sx={navLinkStyles}>
              Account
            </Typography>
            <Typography component={NavLink} to="/feedback-page" sx={navLinkStyles}>
              Feedback
            </Typography>
            <Typography component={NavLink} to="/logout" sx={navLinkStyles}>
              Logout
            </Typography>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

const navLinkStyles = {
  color: '#333',
  textDecoration: 'none',
  fontSize: '14px',
  fontWeight: 500,
  '&:hover': { color: '#00856a' },
};

export default VetNavBar;