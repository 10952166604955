import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  TableCell,
  TableRow,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material";

function AdminAppointmentRow({ apt, theme }) {
  const [status, setStatus] = useState(apt.status);
  const datetime_utc = new Date(apt.datetime_utc + "Z");
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: userTimeZone,
  };

  const localDateTime = datetime_utc.toLocaleString("en-US", options);
  const [date, time] = localDateTime.split(", ");

  return (
    <TableRow>
      <TableCell sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}>
        {apt.pet.name}
      </TableCell>
      <TableCell sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}>
        {date}
      </TableCell>
      <TableCell sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}>
        {time}
      </TableCell>
      <TableCell sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}>
        {apt.reason}
      </TableCell>
      <TableCell sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}>
        Dr. {apt.veterinarian.first_name} {apt.veterinarian.last_name}
      </TableCell>
      <TableCell sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}>
        <Select
          value={status}
          sx={{
            fontSize: isMobile ? "0.75rem" : "1rem",
            width: isMobile ? 75 : "none",
          }}
        >
          <MenuItem value="Scheduled" sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}>Scheduled</MenuItem>
          <MenuItem value="Dictation Complete" sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}>Dictation Complete</MenuItem>
          <MenuItem value="Medical Chart Complete" sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}>
            Medical Chart Complete
          </MenuItem>
          <MenuItem value="Recorded in PIMS" sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}>Recorded in PIMS</MenuItem>
        </Select>
      </TableCell>
    </TableRow>
  );
}

export default AdminAppointmentRow;
