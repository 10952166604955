import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, Button, Paper, Grid, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

function NewAdminWarning( {theme} ) {
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const StyledPaper = styled(Paper)(({ theme }) => ({
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'linear-gradient(135deg, #ffffff 0%, #f0f4f8 100%)',
      }));
      
      const StyledButton = styled(Button)(({ theme }) => ({
        marginTop: isMobile ? theme.spacing(0) : theme.spacing(2),
        padding: isMobile ? '6px 12px' : '12px 24px',
        fontSize: isMobile ? '0.6rem' : '1.1rem',
        borderRadius: '25px',
      }));

    return (
        <ThemeProvider theme={theme}>
        <Box sx={{ backgroundColor: "#BDE9FB", minHeight: "100vh", padding: 2 }}>
            <Container component="main" maxWidth="md">
                <StyledPaper elevation={3}>
                    <Typography variant="h4" component="h1" gutterBottom sx={{
                        color: "black",
                        mb: 3,
                        textTransform: "uppercase",
                        textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)"
                    }}>
                        Important Notice
                    </Typography>
                    <Typography variant="body1" align="center" paragraph>
                        Before proceeding, please ensure that no one else at your practice has already created an admin account.
                    </Typography>
                    <Typography variant="body1" align="center" paragraph>
                        There should be only one practice admin account per practice.
                    </Typography>
                    <Typography variant="body1" align="center" paragraph>
                        If you are not the designated admin for your practice, please create a veterinarian account instead.
                    </Typography>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                            <StyledButton
                                variant="contained"
                                color="primary"
                                onClick={() => navigate('/practice-admin-signup')}
                                sx={{
                                    backgroundColor: '#0FBEB7',
                                    '&:hover': {
                                        backgroundColor: '#0C9B94',
                                    },
                                }}
                            >
                                I am the designated admin
                            </StyledButton>
                        </Grid>
                        <Grid item>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate('/vet-signup')}
                                sx={{
                                    borderColor: '#0FBEB7',
                                    color: '#0FBEB7',
                                    '&:hover': {
                                        borderColor: '#0C9B94',
                                        color: '#0C9B94',
                                    },
                                }}
                            >
                                I need a veterinarian account
                            </StyledButton>
                        </Grid>
                    </Grid>
                </StyledPaper>
            </Container>
        </Box>
        </ThemeProvider>
    );
}

export default NewAdminWarning;