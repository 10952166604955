import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import StandardButton from "./StyledComponents/StandardButton";

function VetCard({ vet }) {
  return (
    <Card
      sx={{
        backgroundColor: "#E3F2FD",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        width: "calc(33.33% - 20px)",
        maxWidth: "350px",
        marginBottom: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between", 
        minHeight: "450px",
      }}
    >
      <CardMedia
        component="img"
        image={vet.profile}
        alt="profile-picture"
        sx={{
          width: "200px",
          height: "auto",
          maxHeight: "800px",
          objectFit: "cover",
          objectPosition: "center",
          marginTop: "20px",
          borderRadius: "50%",
          marginLeft: "auto",
          marginRight: "auto",
          display: "block",
        }}
      />
      <CardContent
        sx={{
          padding: "10px",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between", // This ensures the button is always at the bottom
          minHeight: "10px",
        }}
      >
      <Typography
        variant="h4"
        component="div"
        sx={{
          margin: "5px 0",
          fontSize: "1.5rem",
          color: "#212121",
          textAlign: "center",
        }}
      >
        {vet.first_name} {vet.last_name} {vet.degrees}
      </Typography>
      </CardContent>
      <CardContent
        sx={{
          padding: "20px",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between", // This ensures the button is always at the bottom
          minHeight: "200px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flexGrow: 1, // This will allow the text content to take up flexible space
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ margin: "5px 0", fontSize: "1rem", color: "#757575" }}
          >
            Est. {Math.round(vet.distance)} miles
          </Typography>
          <Typography
            variant="body1"
            sx={{ margin: "5px 0", fontSize: "1rem", color: "#757575" }}
          >
            {vet.city}, {vet.state} {vet.zip_code}
          </Typography>
          <Typography
            variant="body1"
            sx={{ margin: "5px 0", fontSize: "1rem", color: "#757575" }}
          >
            {vet.specialty}
          </Typography>
          <Link
            to={`/veterinarian/${vet.id}`}
            style={{ textDecoration: "none" }}
          >
            <StandardButton text="Schedule Virtual Visit" />
          </Link>
        </Box>
      </CardContent>
    </Card>
  );
}

export default VetCard;
