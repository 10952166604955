import StandardButton from "./StyledComponents/StandardButton";
import React, { useEffect, useState } from "react";
import VetAppointmentRow from "./VetAppointmentRow";
import ArrowDropDownIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import NewAppointment from "./NewAppointment/NewAppointment";
import { ThemeProvider } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useNavigate } from "react-router-dom";
import * as appointmentApi from "../api/appointmentApi";
import { getUserFromToken } from "../api/adminApi";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

function VetAppointmentTable({ theme }) {
  const [apts, setApts] = useState([]);
  const [filteredApts, setFilteredApts] = useState([]);
  const [editableData, setEditableData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expandedId, setExpandedId] = useState(null);
  const [open, setOpen] = useState(false);
  const [view, setView] = useState("upcoming");
  const [petOptions, setPetOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedPetName, setSelectedPetName] = useState("");
  const [vetOptions, setVetOptions] = useState([]);
  const [selectedVet, setSelectedVet] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  dayjs.extend(utc);
  dayjs.extend(timezone);

  console.log("Editable Data:", editableData);
  // Set user data on mount
  useEffect(() => {
    const user = getUserFromToken();
    if (!user) {
      navigate("/login");
      return;
    }
    setUserData(user);
  }, [navigate]);

  // Only fetch appointments after we have user data
  useEffect(() => {
    if (!userData?.sub) return;
    fetchAppointments();
    // fetchMoreData();
  }, [userData]);

  const fetchAppointments = () => {
    appointmentApi
      .fetchAppointments()
      .then((aptArray) => {
        setApts(aptArray);
        console.log(aptArray);
        setFilteredApts(aptArray);
        // setEditableData(aptArray);
        const vets = [
          ...new Set(
            aptArray.map(
              (apt) =>
                `${apt.veterinarian.first_name} ${apt.veterinarian.last_name}`
            )
          ),
        ];
        setVetOptions(vets);
      })
      .catch((error) => {
        console.error("Failed to fetch appointments:", error);
        if (error.message === "Unauthorized") {
          localStorage.removeItem("token");
          navigate("/login");
        }
      });
  };

  useEffect(() => {
    console.log(selectedPetName, selectedVet, selectedDate, view);
    let filtered = apts;
    if (selectedPetName) {
      filtered = filtered.filter((apt) => apt.pet.name === selectedPetName);
    }
    if (selectedVet) {
      filtered = filtered.filter(
        (apt) =>
          `${apt.veterinarian.first_name} ${apt.veterinarian.last_name}` ===
          selectedVet
      );
    }
    if (selectedDate) {
      filtered = filtered.filter((apt) => {
        const aptDate = new Date(apt.datetime_utc);
        return aptDate.toDateString() === selectedDate.toDateString();
      });
    }
    if (view) {
      filtered = filterAptsByDate(filtered);
      console.log("filtered appointments:", filtered);
    }
    setFilteredApts(filtered);
  }, [selectedPetName, selectedVet, selectedDate, view, apts]);

  const handleViewChange = (event, newView) => {
    if (newView) setView(newView); // Only update if a valid view is selected
  };

  const petIds = [...new Set(apts.map((apt) => apt.pet_id))];

  // Fetch pet name options based on user input
  useEffect(() => {
    if (!inputValue || inputValue.length <= 1 || !userData?.sub) return;

    appointmentApi
      .fetchPetOptions(inputValue)
      .then((data) => {
        setPetOptions(data);
      })
      .catch((error) => {
        console.error("Failed to fetch pet options:", error);
        setPetOptions([]);
        if (error.message === "Unauthorized") {
          localStorage.removeItem("token");
          navigate("/login");
        }
      });
  }, [inputValue, userData, navigate]);

  // Handle filter change
  const handleFilterChange = (event, value) => {
    console.log("Pet filter changed to:", value);
    setSelectedPetName(value);
  };

  const handleVetChange = (event) => {
    setSelectedVet(event.target.value);
  };

  const resetFilters = () => {
    const updatedFilteredApts = filterAptsByDate(apts);
    setSelectedPetName("");
    setSelectedVet("");
    setSelectedDate(null);
    setFilteredApts(updatedFilteredApts);
  };

  const filterAptsByDate = (aptsArray) => {
    const now = new Date();
    return aptsArray.filter((apt) => {
      const aptDate = new Date(apt.datetime_utc);
      if (view === "upcoming") {
        return aptDate >= now;
      } else {
        return aptDate < now;
      }
    });
  };

  const handleAccordionChange = (id) => {
    setExpandedId((prevId) => (prevId === id ? null : id)); // Toggle only for the specific id
  };

  const handleChange = (field, value) => {
    setEditableData({
      ...editableData,
      [field]: value,
    });
  };

  let lastDate = null;

  // Early return if no user data
  console.log(userData);
  if (!userData?.sub) {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ backgroundColor: "#BDE9FB", padding: isMobile ? 1 : 2 }}>
          <Typography>Loading...</Typography>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "10px",
            padding: isMobile ? 2 : 3,
            maxHeight: "100%",
            maxWidth: "100%",
            boxSizing: "border-box",
            overflowX: "hidden",
            overflow: "auto",
            mt: isMobile ? 1 : 0,
            marginRight: isMobile ? 1 : 2,
            marginLeft: isMobile ? 1 : 2,
          }}
        >
          <Box
            sx={{
              mt: 5,
              mb: 1,
              ml: "8vw",
              alignSelf: "flex-start",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "black",
                mb: isMobile ? 0.5 : 1,
                display: "inline-block",
                fontSize: isMobile ? "0.8rem" : "1rem",
              }}
            >
              Welcome, {userData.sub.username}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                mb: 1,
              }}
            >
              <Typography
                vvariant={isMobile ? "h6" : "h4"}
                sx={{
                  color: "black",
                  mb: isMobile ? 0 : 1,
                  fontSize: isMobile ? "1.2rem" : "1.5rem",
                  textTransform: "capitalize",
                }}
              >
                Appointments
              </Typography>
              <IconButton
                onClick={resetFilters}
                sx={{
                  ml: 1,
                  mb: 1,
                  backgroundColor: "#0FBEB7",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                    color: "#757575",
                  },
                }}
                size="small"
              >
                <RestartAltIcon />
              </IconButton>
            </Box>
          </Box>

          {apts.length > 0 ? (
            <Paper
              sx={{
                width: "80vw",
                overflow: "hidden",
                borderRadius: "15px",
                marginTop: isMobile ? 3 : 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: isMobile ? 2 : 5,
                  ml: 2,
                  mr: 2,
                  mt: 2,
                  height: "75px",
                  alignItems: "center",
                  flexWrap: isMobile ? "wrap" : "nowrap",
                }}
              >
                <ToggleButtonGroup
                  value={view}
                  exclusive
                  onChange={handleViewChange}
                  aria-label="appointment view"
                  sx={{ ml: 3 }}
                >
                  <ToggleButton
                    sx={{
                      textTransform: "capitalize",
                      fontSize: isMobile ? "0.8rem" : "1rem",
                    }}
                    value="upcoming"
                    aria-label="upcoming"
                  >
                    Upcoming
                  </ToggleButton>
                  <ToggleButton
                    sx={{
                      textTransform: "capitalize",
                      fontSize: isMobile ? "0.8rem" : "1rem",
                      paddingX: 4,
                    }}
                    value="past"
                    aria-label="past"
                  >
                    Past
                  </ToggleButton>
                </ToggleButtonGroup>
                <Autocomplete
                  sx={{
                    width: isMobile ? "40%" : "20%",
                    "& .MuiInputBase-input": {
                      fontSize: isMobile ? "0.75rem" : "1rem",
                    },
                  }}
                  options={petOptions}
                  getOptionLabel={(option) => option}
                  onInputChange={(e, value) => setInputValue(value)}
                  onChange={handleFilterChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={isMobile ? "Pet" : "Pet Name"}
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        sx: { fontSize: isMobile ? "0.75rem" : "1rem" },
                      }}
                    />
                  )}
                />
                <DatePicker
                  label="Filter by Date"
                  value={selectedDate}
                  onChange={(newDate) => setSelectedDate(newDate)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ fontSize: isMobile ? "0.7rem" : "1rem" }}
                    />
                  )}
                />
                <FormControl sx={{ width: isMobile ? "40%" : "20%" }}>
                  <InputLabel id="vet-select-label" shrink>
                    Veterinarians
                  </InputLabel>
                  <Select
                    onChange={handleVetChange}
                    labelId="vet-select-label"
                    value={selectedVet}
                    label="Veterinarians"
                    displayEmpty
                    sx={{
                      color: "black",
                      fontSize: isMobile ? "0.75rem" : "1rem",
                    }}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {vetOptions.map((vet, i) => (
                      <MenuItem
                        sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}
                        key={i}
                        value={vet}
                      >
                        {vet}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <StandardButton
                  text="+ Add Appointment"
                  handleClick={() => setOpen(true)}
                />
              </Box>
              <NewAppointment
                open={open}
                theme={theme}
                handleClose={() => setOpen(false)}
                onAppointmentCreated={fetchAppointments}
                petIds={petIds}
              />
              <Paper sx={{ mt: 3 }}>
                {filteredApts?.map((apt) => {
                  const aptDate = new Date(
                    apt.datetime_utc
                  ).toLocaleDateString();
                  const showDivider = lastDate !== aptDate;
                  lastDate = aptDate;
                  return (
                    <React.Fragment key={apt.id}>
                      {showDivider && (
                        <Box
                          sx={{
                            backgroundColor: "#F0F0F0",
                            height: "70px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: "#F0F0F0", // Light grey background
                              padding: "8px 16px",
                              borderRadius: "4px",
                              marginBottom: "8px",
                            }}
                          >
                            {new Date(apt.datetime_utc).toLocaleDateString(
                              "en-US",
                              {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </Typography>
                        </Box>
                      )}
                      <Accordion
                        key={apt.id}
                        expanded={expandedId === apt.id}
                        onChange={() => {
                          if (!isEditing) {
                            handleAccordionChange(apt.id);
                          }
                        }}
                      >
                        <AccordionSummary
                          onClick={() => {
                            setEditableData({
                              datetime_utc: apt.datetime_utc,
                              owner_first_name: apt.pet.owner_first_name,
                              owner_last_name: apt.pet.owner_last_name,
                              owner_email: apt.pet.owner?.email || null,
                              breed: apt.pet.breed,
                              age: apt.pet.age,
                              sex: apt.pet.sex,
                              reason: apt.reason,
                            });
                          }}
                          expandIcon={<ArrowDropDownIcon />}
                          sx={{ height: "100px", marginX: 3 }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "left",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ width: "30%" }}>
                              {!isEditing ? (
                                <Typography sx={{ fontSize: "18px" }}>
                                  {dayjs
                                    .utc(apt.datetime_utc)
                                    .tz(dayjs.tz.guess())
                                    .format("h:mm A")}
                                </Typography>
                              ) : (
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    components={["DateTimePicker"]}
                                    sx={{ width: "80%" }}
                                  >
                                    <DateTimePicker
                                      label="Appointment date and time"
                                      value={dayjs(editableData.datetime_utc)}
                                      onChange={(newValue) => {
                                        console.log(newValue);
                                        if (newValue) {
                                          setEditableData((prevData) => ({
                                            ...prevData,
                                            datetime_utc:
                                              newValue.toISOString(),
                                          }));
                                        }
                                      }}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              )}
                            </Box>

                            <Box sx={{ width: "30%", ml: 1 }}>
                              {!isEditing ? (
                                <Typography sx={{ fontSize: "18px" }}>
                                  {" "}
                                  <strong>
                                    {" "}
                                    {apt.pet.name} with{" "}
                                    {apt.veterinarian.first_name +
                                      " " +
                                      apt.veterinarian.last_name}{" "}
                                  </strong>
                                  <br />
                                  Reason: {apt.reason}
                                </Typography>
                              ) : (
                                <TextField
                                  label="Reason for Vist"
                                  fullWidth
                                  margin="normal"
                                  value={editableData.reason}
                                  onChange={(e) =>
                                    handleChange("reason", e.target.value)
                                  }
                                />
                              )}
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "20%",
                                ml: 1,
                              }}
                            >
                              <Typography sx={{ fontSize: "18px" }}>
                                {apt.meeting_link !== "In Office Visit"
                                  ? "Telehealth Appointment"
                                  : "In Office Visit"}
                              </Typography>
                              {apt.meeting_link !== "In Office Visit" && (
                                <Typography
                                  component="a"
                                  href={apt.meeting_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  sx={{
                                    textDecoration: "none",
                                    color: "primary",
                                    fontSize: "18px",
                                  }}
                                >
                                  Join Meeting
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ marginX: 3 }}>
                          <VetAppointmentRow
                            apt={apt}
                            apts={apts}
                            setApts={setApts}
                            theme={theme}
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                            editableData={editableData}
                            setEditableData={setEditableData}
                            handleChange={handleChange}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </React.Fragment>
                  );
                })}
              </Paper>
            </Paper>
          ) : (
            <p>No appointments to display</p>
          )}
        </Box>
        {/* </Box> */}
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default VetAppointmentTable;
