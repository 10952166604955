export const emailSummaryWithDiagnosis = async (apiUrl, aptId, token, email) => {
    const response = await fetch(`${apiUrl}/api/v1/email-summary-with-diagnosis/${aptId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
      credentials: "include",
    });
    if (!response.ok) throw new Error("Network response was not ok");
    return response.json();
  };