export const checkSessionApi = async (apiUrl, token) => {
    const response = await fetch(`${apiUrl}/api/v1/check_session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    return response;
  };