import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useTokenRedirect from '../utils/authUtils';
import { checkSessionApi } from '../api/checkSessionApi';

function Pets() {
    const [pets, setPets] = useState([]);
    const [userType, setUserType] = useState(null);
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const { getTokenOrRedirect } = useTokenRedirect();
    const token = getTokenOrRedirect()

    const checkSession = useCallback(async () => {
        try {
            const response = await checkSessionApi(apiUrl, token);
            if (response.ok) {
                const data = await response.json();
                setUserType(data.user_type);
            } else if (response.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error checking session:', error);
        }
    }, [navigate, apiUrl]);

    const fetchPets = useCallback(async () => {
        try {
            const response = await fetch(`${apiUrl}/api/v1/pets`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();
                setPets(data);
            } else if (response.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error fetching pets:', error);
        }
    }, [navigate, apiUrl]);

    useEffect(() => {
        checkSession();
    }, [checkSession]);

    useEffect(() => {
        if (userType) {
            fetchPets();
        }
    }, [userType, fetchPets]);

    return (
        <div className='pets-container'>
            <h1 className='title'>My Pets</h1>
            {userType === 'owner' ? pets.map(pet => (
                <div key={pet.id} className='pet-card'>
                    <h2>{pet.name}</h2>
                    <p>{pet.breed}</p>
                    <p>{pet.sex}</p>
                    <p>{pet.age}</p>
                    <p>{pet.weight}</p>
                </div>
            )) : <h1>You are not authorized to view this page</h1>}
        </div>
    );
}

export default Pets;