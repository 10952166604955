export const fetchOwnerDataApi = async (apiUrl, ownerId, token) => {
    const response = await fetch(`${apiUrl}/owner/${ownerId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      return response;
}