import { TextField, MenuItem } from "@mui/material";

function SelectPetMenu({ newAppointment, handleChange, isAddingNewPet, pets }) {
  return (
    <TextField
      select
      label="Pet"
      name="pet_id"
      value={newAppointment.pet_id}
      onChange={handleChange}
      fullWidth
      margin="normal"
      required
      disabled={isAddingNewPet}
    >
      {pets.map((pet) => (
        <MenuItem key={pet.id} value={pet.id}>
          {pet.name}
        </MenuItem>
      ))}
      <MenuItem value="new">
        <b>+ Add New Pet</b>
      </MenuItem>
    </TextField>
  );
}

export default SelectPetMenu;
