import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import StandardButton from "./StyledComponents/StandardButton";
import useTokenRedirect from "../utils/authUtils";
import {
  Box,
  Typography,
  useMediaQuery,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

function OwnerAppointmentRow({ apt, theme, setFilteredApts }) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const datetime_utc = new Date(apt.datetime_utc + "Z");
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getTokenOrRedirect } = useTokenRedirect();
  const token = getTokenOrRedirect();

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: userTimeZone,
  };

  const localDateTime = datetime_utc.toLocaleString("en-US", options);
  const [date, time] = localDateTime.split(", ");

  const handleDeleteAppointment = async () => {
    try {
      // Step 1: Delete appointment from Calendly
      const calendlyEventUri = `${apt.calendly_event_uri}/cancellation`; // Retrieve the stored Calendly event URI from the appointment
      if (calendlyEventUri) {
        const calendlyResponse = await fetch(calendlyEventUri, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CALENDLY_API_KEY}`, // Make sure your API key is correctly set
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ reason: deleteReason }),
        });

        if (!calendlyResponse.ok) {
          throw new Error("Failed to delete appointment from Calendly");
        }
        console.log("Appointment deleted from Calendly");
      }

      // Step 2: Delete appointment from local database
      const response = await fetch(`${apiUrl}/api/v1/appointment/${apt.id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          throw new Error(
            "Failed to delete appointment from the local database"
          );
        }
      } else {
        console.log("Appointment deleted from local database");
      }

      setFilteredApts((prevFilteredApts) =>
        prevFilteredApts.filter((a) => a.id !== apt.id)
      );
    } catch (error) {
      console.error("Error deleting appointment:", error);
    }
  };

  const handleUpdateAppointment = async () => {
    try {
      // Step 1: Delete appointment from Calendly
      const calendlyEventUri = `${apt.calendly_event_uri}/cancellation`; // Retrieve the stored Calendly event URI from the appointment
      if (calendlyEventUri) {
        const calendlyResponse = await fetch(calendlyEventUri, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CALENDLY_API_KEY}`, // Make sure your API key is correctly set
            "Content-Type": "application/json",
          },
          data: '{"reason":"Guest cancelled through Ailovet - will reschedule"}',
        });

        if (!calendlyResponse.ok) {
          throw new Error("Failed to delete appointment from Calendly");
        }
        console.log("Appointment deleted from Calendly");
      }
      // Step 2: Delete appointment from local database
      const response = await fetch(`${apiUrl}/api/v1/appointment/${apt.id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          throw new Error(
            "Failed to delete appointment from the local database"
          );
        }
      } else {
        console.log("Appointment deleted from local database");
      }

      setFilteredApts((prevFilteredApts) =>
        prevFilteredApts.filter((a) => a.id !== apt.id)
      );

      // Step 3: navigate to scheduling page for that veterinarian
      navigate(`/veterinarian/${apt.veterinarian_id}`);
    } catch (error) {
      console.error("Error deleting appointment:", error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box sx={{ width: "30%", mb: 20 }}>
        <Typography sx={{ mb: 1 }}>
          <strong>Manage Appointment:</strong>
        </Typography>
        <Box sx={{ mb: 1 }}>
          <StandardButton
            text="Update"
            handleClick={() => setOpenUpdateModal(true)}
            size="small"
          />
          <Button
            size="small"
            variant="contained"
            onClick={() => setOpenDeleteModal(true)}
            sx={{
              ml: 2,
              borderRadius: "25px",
              backgroundColor: "#F94349",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#FFFFFF",
                color: "#757575",
              },
            }}
          >
            Cancel
          </Button>
        </Box>
        <Typography sx={{ mb: 1, mt: 4 }}>
          <strong>Patient Records:</strong>
        </Typography>
        {apt.show_summary_to_owner ? (
          <Link
            to={`/final-medical-summary/${apt.id}`}
            style={{ textDecoration: "none" }}
          >
            <Typography
              fontSize={isMobile ? "0.75rem" : "1rem"}
              variant="body2"
              sx={{
                color: apt.show_summary_to_owner ? "#3991da" : "#0fbeb7",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              View Medical Summary
            </Typography>
          </Link>
        ) : (
          <Typography>Medical Summary Pending</Typography>
        )}
      </Box>
      <Box sx={{ width: "30%", mb: 15 }}>
        <Typography sx={{ fontSize: "17px" }}>
          <strong>Veterinarian Name</strong>
          <br />
          {apt?.veterinarian?.first_name + " " + apt?.veterinarian?.last_name}
          <br />
          <br />
          <strong>Veterinarian Email</strong>
          <br />
          {apt?.veterinarian?.email}
          <br />
          <br />
          <strong>Speciality</strong>
          <br />
          {apt?.veterinarian?.specialty}
        </Typography>
      </Box>
      
      <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Deleting an appointment is irreversible. Would you like to continue?
            If yes, please provide a reason for cancellation.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Cancellation Reason"
            fullWidth
            variant="outlined"
            value={deleteReason}
            onChange={(e) => setDeleteReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)} color="secondary">
            No
          </Button>
          <Button
            onClick={() => {
              setOpenDeleteModal(false);
              handleDeleteAppointment();
            }}
            color="secondary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Update Modal */}
      <Dialog open={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Once you update this appointment, your original appointment will be
            cancelled. Would you like to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUpdateModal(false)} color="secondary">
            No
          </Button>
          <Button
            onClick={() => {
              setOpenUpdateModal(false);
              handleUpdateAppointment();
            }}
            color="secondary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default OwnerAppointmentRow;
