import React from 'react';
import {
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  CloudQueue as CloudIcon,
  VideoCall as VideoIcon,
  AccessTime as ClockIcon,
  AttachMoney as MoneyIcon,
  Computer as ComputerIcon,
  Favorite as HeartIcon,
} from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';

function AboutPage({ theme }) {
  const services = [
    {
      title: (
        <>
          Patient Management<br />
          Intelligent Platform
        </>
      ),
      icon: <CloudIcon fontSize="large" color="secondary" />,
      description: "A cloud-based patient management system accessible from anywhere, leveraging AI to streamline your workflow.",
      features: [
        "Access patient records 24/7 from any device",
        "AI-generated medical summaries and differentials",
        "Automated chart creation to save time",
        "Real-time updates and synchronization",
        "Data protection measures in place",
      ],
    },
    {
      title: (
        <>
          Telehealth<br />
          Intelligent Platform
        </>
      ),
      icon: <VideoIcon fontSize="large" color="secondary" />,
      description: "A fully integrated telehealth solution that empowers both veterinarians and pet owners with cutting-edge features.",
      features: [
        "Self-scheduling and billing for clients",
        "Seamless video conferencing integration",
        "AI-assisted real-time charting during consultations",
        "Flexible work hours and remote capabilities",
        "Expand your practice and increase earnings",
      ],
    },
  ];

  return (
    <ThemeProvider theme={theme}>
        <Container maxWidth="lg" sx={{ py: 8 }}>
        <Typography variant="h2" component="h1" align="center" gutterBottom>
            Intelligent Veterinary Platforms
        </Typography>
        
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
            Embrace the joy of being a veterinarian with our DVM AI Intelligence™ core technology, 
            handling your medical charting in real time and enhancing your practice.
        </Typography>

        <Grid container spacing={4} sx={{ mt: 4 }}>
            {services.map((service, index) => (
            <Grid item xs={12} lg={6} key={index}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <Box sx={{ mb: 2 }}>
                    {service.icon}
                    </Box>
                    <Typography variant="h4" component="div" gutterBottom>
                    {service.title}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                    {service.description}
                    </Typography>
                    <List>
                    {service.features.map((feature, featureIndex) => (
                        <ListItem key={featureIndex}>
                        <ListItemIcon>
                            <HeartIcon color="secondary" />
                        </ListItemIcon>
                        <ListItemText primary={feature} />
                        </ListItem>
                    ))}
                    </List>
                </CardContent>
                </Card>
            </Grid>
            ))}
        </Grid>

        <Box sx={{ mt: 6 }}>
            <Typography variant="h4" align="center" gutterBottom>
            Benefits for Veterinarians
            </Typography>
            <Grid container spacing={3} sx={{ mt: 2 }}>
            {[
                { icon: <ClockIcon />, title: "Time-Saving", description: "Reduce administrative work and focus more on patient care and diagnosis." },
                { icon: <MoneyIcon />, title: "Increased Revenue", description: "Expand your practice and boost earnings through telehealth consultations." },
                { icon: <ComputerIcon />, title: "Enhanced Efficiency", description: "Streamline your workflow with AI-assisted charting and diagnostics." },
                { icon: <HeartIcon />, title: "Improved Patient Care", description: "Provide better, more accessible care, leading to improved health outcomes." },
            ].map((benefit, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                    {React.cloneElement(benefit.icon, { color: "secondary" })}
                    <Typography variant="h6" align="center" sx={{ mt: 2 }}>
                    {benefit.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
                    {benefit.description}
                    </Typography>
                </Card>
                </Grid>
            ))}
            </Grid>
        </Box>
        </Container>
    </ThemeProvider>
  );
}

export default AboutPage;