import { useEffect, useState, useCallback } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useNavigate } from "react-router-dom";
import useTokenRedirect from "../utils/authUtils";
import { checkSessionApi } from "../api/checkSessionApi";
import { fetchOwnerDataApi } from "../api/fetchOwnerDataApi";
import { scheduleCalendlyEventApi } from "../api/scheduleCalendlyEventApi";
import { getCalendlyInviteeData } from "../api/scheduleCalendlyEventApi";
import { creatAppointmentApi } from "../api/appointmentApi";

function CalendlyInlineEmbed({ vetId, calendly_url }) {
  const [ownerId, setOwnerId] = useState(null);
  const [petId, setPetId] = useState(null);
  const [prefill, setPrefill] = useState({
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    customAnswers: {
      a1: "",
      a2: "",
      a3: "",
      a4: "",
    },
  });
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const calendlyApiUrl = process.env.REACT_APP_CALENDLY_API_KEY;
  const { getTokenOrRedirect } = useTokenRedirect();
  const token = getTokenOrRedirect();

  const calendlyUrl = calendly_url
    ? calendly_url
    : "https://calendly.com/angela-tolwani/30-minute-calendar-invite";
  // https://calendly.com/elizacohen5

  const checkSession = useCallback(async () => {
    try {
      const response = await checkSessionApi(apiUrl, token);

      if (response.ok) {
        const data = await response.json();
        if (data && data.owner_id) {
          setOwnerId(data.owner_id);
        } else {
          console.warn("Owner ID is missing in the response data.");
        }
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error checking session:", error);
    }
  }, [navigate, apiUrl, token, setOwnerId]);

  const fetchOwnerData = useCallback(async () => {
    if (!ownerId) return;
    try {
      const response = await fetchOwnerDataApi(apiUrl, ownerId, token);
      if (response.ok) {
        const data = await response.json();
        setPrefill({
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          customAnswers: {
            a1: data.pets[0].name,
            a2: data.pets[0].breed,
            a3: data.pets[0].age,
            a4: data.pets[0].sex,
          },
        });
        setPetId(data.pets[0].id);
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error fetching owner data:", error);
    }
  }, [ownerId, navigate, apiUrl]);

  useEffect(() => {
    checkSession();
    fetchOwnerData();
  }, [checkSession, fetchOwnerData]);

  const handleEventScheduled = useCallback(
    (e) => {
      setTimeout(async () => {
        try {
          const event_uri = e.data.payload.event.uri;
          const invitee_uri = e.data.payload.invitee.uri;
          const response = await scheduleCalendlyEventApi(
            event_uri,
            calendlyApiUrl
          );

          if (!response.ok) {
            console.error("Event response error:", response);
            throw new Error("Failed to fetch event data");
          }
          const eventData = await response.json();
          // Fetch invitee data
          const inviteeResponse = await getCalendlyInviteeData(
            invitee_uri,
            calendlyApiUrl
          );
          if (!inviteeResponse.ok) {
            throw new Error("Failed to fetch invitee data");
          }
          const inviteeData = await inviteeResponse.json();
          const appointmentData = {
            pet_id: petId,
            veterinarian_id: vetId,
            datetime_utc: eventData.resource.start_time,
            meeting_link: eventData.resource.location.join_url, // Check if join_url is available now
            reason: inviteeData.resource.questions_and_answers[4].answer,
            primary_vet: inviteeData.resource.questions_and_answers[5].answer,
            calendly_event_uri: event_uri,
          };

          // Send appointment data to your backend API
          const appointmentResponse = await creatAppointmentApi(
            apiUrl,
            appointmentData,
            token
          );
          if (!appointmentResponse.ok) {
            if (appointmentResponse.status === 401) {
              localStorage.removeItem("token");
              navigate("/login");
            } else {
              throw new Error("Failed to create appointment");
            }
          }
        } catch (error) {
          console.error("Error handling event scheduled:", error);
        }
      }, 3000); // 3-second delay before executing the logic
    },
    [petId, vetId, navigate, apiUrl]
  );

  useCalendlyEventListener({
    onEventScheduled: handleEventScheduled,
  });

  return (
    <>
      <div>
        <InlineWidget
          url={calendlyUrl}
          prefill={prefill}
          styles={{ height: "600px" }}
        />
      </div>
    </>
  );
}

export default CalendlyInlineEmbed;
