export const updateSummaryWithDiagnosis = async (apiUrl, aptId, token, editableData) => {
    const response = await fetch(`${apiUrl}/api/v1/update-summary-with-diagnosis/${aptId}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editableData),
      credentials: "include",
    });
    if (!response.ok) throw new Error("Network response was not ok");
    return response.json();
  };