import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";

function DateFilter({ selectedDate, setSelectedDate, isMobile }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={isMobile ? "" : "Filter by Date"}
        value={selectedDate}
        onChange={(newDate) => setSelectedDate(newDate)}
        sx={{
          "& .MuiInputBase-input": {
            fontSize: isMobile ? "0.75rem" : "1rem",
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            InputLabelProps={{
              sx: { fontSize: isMobile ? "0.75rem" : "1rem" },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default DateFilter;
