import VetAppointmentTable from "../components/VetAppointmentTable";
import OwnerAppointmentTable from "../components/OwnerAppointmentTable";
import AdminAppointmentTable from "../components/AdminAppointmentTable/AdminAppointmentTable";
import VetAppointmentTableMobile from "../components/VetAppointmentTableMobile";
import { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import useTokenRedirect from "../utils/authUtils";
import { checkSessionApi } from "../api/checkSessionApi";

function Appointments({ theme }) {
  const [userType, setUserType] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const { getTokenOrRedirect } = useTokenRedirect();
  const token = getTokenOrRedirect();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await checkSessionApi(apiUrl, token);
        if (response.ok) {
          const data = await response.json();
          if (data && data.user_type) {
            setUserType(data.user_type);
          }
        } else {
            console.warn("User type is missing in the response data.");
          localStorage.removeItem("token");
          navigate("/login");
        }
      } catch (error) {
        console.error("Session check failed:", error);
        localStorage.removeItem("token");
        navigate("/login");
      }
    };

    checkSession();
  }, [navigate, apiUrl]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ position: "relative", m: 2 }}>
        {userType === "vet" ? (
          isMobile ? (
            <VetAppointmentTableMobile theme={theme} />
          ) : (
            <VetAppointmentTable theme={theme} />
          )
        ) : userType === "owner" ? (
          <OwnerAppointmentTable theme={theme} />
        ) : userType === "admin" ? (
          <AdminAppointmentTable theme={theme} />
        ) : (
          <h1>Loading...</h1>
        )}
      </Box>
    </ThemeProvider>
  );
}

export default Appointments;
