import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, TextField, Button, Paper, Grid, Select, MenuItem, InputLabel, FormControl, Stepper, Step, StepLabel, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'linear-gradient(135deg, #ffffff 0%, #f0f4f8 100%)',
}));
  
const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(3),
    padding: '12px 24px',
    fontSize: '1.1rem',
}));

function OwnerSignup({ theme }) {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        first_name: '',
        last_name: '',
        email: '',
        city: '',
        state: '',
        zip_code: '',
        pet_name: '',
        pet_breed: '',
        pet_sex: '',
        pet_age: '',
        pet_weight: '',
        is_owner: true,
        vet_id: null,
        owner_id: null
    });
    const apiUrl = process.env.REACT_APP_API_URL || '';

    const states = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        try {
            const response = await fetch(`${apiUrl}/api/v1/signup`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
            },
                body: JSON.stringify(formData)
            })
            const result = await response.json();

            if (response.ok) {
                setSuccess(result.message);
                if (result.token) {
                    localStorage.setItem('token', result.token);
                }
                setTimeout(() => navigate('/login'), 3000);
            } else {
                setError(result.message || 'An error occurred during signup.');
            }

        } catch (error) {
            setError('An error occurred. Please try again.')
            console.error('Error:', error);
        }
    }
    
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="md">
                <StyledPaper elevation={3}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Pet Owner Signup
                    </Typography>
                    <Box 
                        component="form" 
                        onSubmit={handleSubmit} 
                        noValidate 
                        sx={{ mt: 3, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    name="username"
                                    label="Username"
                                    value={formData.username}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    name="first_name"
                                    label="First Name"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    name="last_name"
                                    label="Last Name"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="email"
                                    label="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    name="city"
                                    label="City"
                                    value={formData.city}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl fullWidth required>
                                    <InputLabel>State</InputLabel>
                                    <Select
                                        name="state"
                                        value={formData.state}
                                        onChange={handleChange}
                                    >
                                        {states.map(state => (
                                            <MenuItem key={state} value={state}>
                                                {state}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    required
                                    fullWidth
                                    name="zip_code"
                                    label="Zip Code"
                                    value={formData.zip_code}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="pet_name"
                                    label="Pet Name"
                                    value={formData.pet_name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="pet_breed"
                                    label="Pet Breed"
                                    value={formData.pet_breed}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth required>
                                    <InputLabel>Pet Sex</InputLabel>
                                    <Select
                                        required
                                        name="pet_sex"
                                        value={formData.pet_sex}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Male">Male</MenuItem>
                                        <MenuItem value="Male Neutered">Male Neutered</MenuItem>
                                        <MenuItem value="Female">Female</MenuItem>
                                        <MenuItem value="Female Spayed">Female Spayed</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="pet_age"
                                    label="Pet Age"
                                    value={formData.pet_age}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="pet_weight"
                                    label="Pet Weight (kg)"
                                    value={formData.pet_weight}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <StyledButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{
                                mt: 3,
                                mb: 2,
                                width: "50%",
                                borderRadius: "25px",
                                backgroundColor: "#0FBEB7",
                                "&:hover": {
                                    backgroundColor: "#0C9B94",
                                },
                                    }}
                                >
                                    Sign Up
                                </StyledButton>
                            </Grid>
                        </Grid>
                    </Box>
                    {error && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {error}
                        </Alert>
                    )}
                    {success && (
                        <Alert severity="success" sx={{ mt: 2 }}>
                            {success}
                        </Alert>
                    )}
                </StyledPaper>
            </Container>
        </ThemeProvider>
    );
  }
  
  export default OwnerSignup;

